/* global ajax_object */

// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; import './autoload/lazyload.js'; // eslint-disable-line

// Import local dependencies
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';
import 'select2';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      setTimeout(function () {
        if ($that.width() / width > $that.height() / height) {
          $(this).css({
            width: '100%',
            height: 'auto',
          });
        } else {
          $(this).css({
            width: ($that.height() * width) / height,
            height: '100%',
          });
        }
      }, 500);
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Filter for positions
   */
  $('#jobFilter').on('change', function () {
    let curr = $(this).val();

    $('.positions-list__accordion')
      .find('.accordion-item')
      .each(function () {
        if (curr != 'all') {
          $(this).hide();
          if ($(this).data('id') == curr) {
            $(this).show();
            $(this).eq(0).find('.accordion-title').trigger('click');
          }
        } else {
          $(this).show();
        }
      });
  });

  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .not('[href*="#marketTab"]')
    .click(function () {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') ===
          this.pathname.replace(/^\//, '') &&
        location.hostname === this.hostname
      ) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
        $('.parent-menu-item .submenu li.current-menu-item a').removeClass(
          'active'
        );
        $(this).addClass('active');
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length
          ? target
          : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          //event.preventDefault();
          $('html, body').animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(':focus')) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });

  /**
   * First click on iPad header menu
   */
  if ($(window).width() < 1025) {
    $(document).on('touchstart', '.menu li > a, .submenu li > a', function (e) {
      // eslint-disable-next-line no-empty
      if ($('a[href*="#"]')) {
      } else {
        e.preventDefault();
        window.location.href = $(this).attr('href');
      }
    });
  }

  /**
   * Input file add value to custom field
   */
  function inputFilePlaceholder() {
    $('.gf-file input[type="file"]').on('change', function () {
      var file = $(this)[0].files[0].name;
      $(this).parent().next().text(file);
    });
  }
  // Init
  inputFilePlaceholder();

  /**
   * Custom select
   */
  $('.custom-select, .select2 select').select2({
    width: '100%',
    minimumResultsForSearch: Infinity,
  });

  /**
   * Anchor to choose projects
   */
  // let winLoc = window.location.search;
  // if ($('#projectFilter').length && winLoc) {
  //   setTimeout(function () {
  //     let destination = $('.featured-projects__top').offset().top + 'px';
  //     $('body, html').animate({ scrollTop: destination }, 500);
  //   }, 1000);
  // }

  /**
   * Projects filter by markets
   */
  $('#projectFilter').on('change', function () {
    let $this = $(this);

    if ($this.val() != '0') {
      window.location.href = `${location.href.replace(
        window.location.search,
        ''
      )}?filter=${$this.val()}`;
    } else {
      window.location.href = location.href.replace(window.location.search, '');
    }

    // let market = $(this).val(),
    //   data = {
    //     action: 'filter_projects',
    //     tax: market,
    //   };
    //
    // $.ajax({
    //   url: ajax_object.ajax_url,
    //   data: data,
    //   type: 'POST',
    //   beforeSend: function () {
    //     $('.body__inner').addClass('is-loading');
    //   },
    //   success: function (data) {
    //     console.log(data);
    //     $('.body__inner').removeClass('is-loading');
    //     $('.featured-projects__loop-outer').html(data);
    //     // Load More init
    //     projectsLoadMore();
    //   },
    //   error: function (err) {
    //     console.log(err);
    //   },
    // });
  });

  /**
   * Ajax load more button projects
   */
  function projectsLoadMore() {
    let $projectsContainer = $('.featured-projects__loop-inner'),
      page = $('.featured-projects__loop').find('.js-loadmore').data('page');
    if ($projectsContainer.length) {
      $('.js-loadmore').on('click', function (e) {
        e.preventDefault();

        let button = $(this),
          posts = button.data('posts'),
          max = button.data('max'),
          data = {
            action: 'loadmore_projects',
            posts: posts,
            page: page,
          };

        $.ajax({
          url: ajax_object.ajax_url,
          data: data,
          type: 'POST',
          beforeSend: function () {
            button.addClass('is-loading');
          },
          success: function (data) {
            if (data) {
              button.removeClass('is-loading');
              $projectsContainer.append(data);
              page++;

              if (page == max) {
                button.remove();
              }
            } else {
              button.remove();
            }
          },
          error: function (err) {
            console.log(err);
          },
        });
      });
    }
  }
  // Load More init
  projectsLoadMore();

  /**
   * Ajax load more button posts
   */
  function postsLoadMore() {
    let $postsContainer = $('.news__loop-inner'),
      page = $('.news__loop-more').find('.js-loadmore').data('page');
    if ($postsContainer.length) {
      $('.js-loadmore').on('click', function (e) {
        e.preventDefault();

        let button = $(this),
          posts = button.data('posts'),
          max = button.data('max'),
          data = {
            action: 'loadmore_posts',
            posts: posts,
            page: page,
          };

        $.ajax({
          url: ajax_object.ajax_url,
          data: data,
          type: 'POST',
          beforeSend: function () {
            button.addClass('is-loading');
          },
          success: function (data) {
            if (data) {
              button.removeClass('is-loading');
              $postsContainer.append(data);
              page++;

              if (page == max) {
                button.remove();
              }
            } else {
              button.remove();
            }
          },
          error: function (err) {
            console.log(err);
          },
        });
      });
    }
  }
  // Load More init
  postsLoadMore();

  /**
   * Ajax posts filter
   */
  $('#newsFilter').on('change', function () {
    let cat = $(this).val(),
      data = {
        action: 'filter_posts',
        cat: cat,
      };

    $.ajax({
      url: ajax_object.ajax_url,
      data: data,
      type: 'POST',
      beforeSend: function () {
        $('.body__inner').addClass('is-loading');
      },
      success: function (data) {
        $('.body__inner').removeClass('is-loading');
        $('.news__loop-outer').html(data);
        // Load More init
        postsLoadMore();
      },
      error: function (err) {
        console.log(err);
      },
    });
  });

  /**
   * Careers slider
   */
  $('.careers-slider__list').slick({
    arrows: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  /**
   * Home news slider
   */
  $('.home-news__list').slick({
    arrows: true,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   * Single project slider
   */
  $('.single-projects-hero__slider').slick({
    arrows: true,
    dots: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.single-projects-hero__slider-nav',
  });

  $('.single-projects-hero__slider-nav').slick({
    arrows: false,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.single-projects-hero__slider',
    focusOnSelect: true,
  });

  // /**
  //  * Markets slider
  //  */
  // $('.markets-list__slider').slick({
  //   arrows: true,
  //   dots: false,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1201,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 641,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // });

  /**
   * About Sustainability slider
   */
  $('.about-sustainability__list').slick({
    arrows: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  // $('.about-sustainability__partners').slick({
  //   arrows: true,
  //   dots: false,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // });

  /**
   * Special project featured slider
   */
  $('.project-special-featured__list').slick({
    arrows: true,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   * Home services slider
   */
  $('.home-services__list').slick({
    arrows: true,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   * Partners slider
   */
  $('.about-giving__partners').slick({
    arrows: true,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 641,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   * Team slider
   */
  // $('.about-team__list').slick({
  //   arrows: true,
  //   dots: false,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1281,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 641,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // });

  /**
   * Modal init
   */
  $('.js-modal').each(function () {
    let $this = $(this).data('src');
    $('.js-modal[data-src=' + $this + ']').fancybox({
      baseClass: 'fancybox-modal',
      touch: false,
      //selector: '.js-modal',
      hash: false,
      //backFocus: false,
      helpers: {
        overlay: {
          locked: true,
        },
      },
      arrows: false,
      keyboard: false,
      beforeShow: function () {
        $('.fancybox-infobar').hide();
        $('html').addClass('fancybox-active');

        // Services Modal slider init
        let id = $(this)[0].src;

        $(id)
          .find('.modal-service__projects')
          .each(function () {
            if ($(this).length) {
              $(this).not('.slick-initialized').slick({
                arrows: true,
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                draggable: false,
              });
            }
            // init
            initInsideSliders($(this));
          })
          .on('beforeChange', function () {
            //init
            initInsideSliders($(this));
          });
      },
      afterShow: function () {
        let id = $(this)[0].src;

        $('[data-fancybox]').fancybox({
          afterClose: function () {
            $(id)
              .find(
                '.modal-service__projects, .modal-service__slider, .modal-service__slider-nav'
              )
              .slick('refresh');
          },
        });
      },
      afterClose: function () {
        $('html').removeClass('fancybox-active');
      },
      btnTpl: {
        smallBtn:
          '<button data-fancybox-close="" class="fancybox-button fancybox-button--close fancybox-close-small" title="Close">' +
          '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 79.51 82" width="16px" height="16px"><defs><clipPath id="clip-path" transform="translate(0 0)"><rect width="79.51" height="82" style="fill:none"/></clipPath></defs><path fill="#000" d="M78.6,69.2,50.4,41,78.6,12.8a2.9,2.9,0,0,0,0-4.2L70.1.1,39.8,30.4,9.4,0,.9,8.5a2.9,2.9,0,0,0,0,4.2L29.1,40.9,1,69.2a2.9,2.9,0,0,0,0,4.2l8.5,8.5L39.8,51.6,70.2,82l8.5-8.5a3.11,3.11,0,0,0-.1-4.3" transform="translate(0 0)"/></svg>' +
          '</button>',
      },
    });
  });

  // Init sliders inside modal
  function initInsideSliders(curr) {
    curr
      .find('.modal-service__slider')
      .not('.slick-initialized')
      .slick({
        arrows: false,
        dots: false,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: curr.find('.modal-service__slider-nav'),
      });

    curr
      .find('.modal-service__slider-nav')
      .not('.slick-initialized')
      .slick({
        arrows: false,
        dots: false,
        focusOnSelect: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: curr.find('.modal-service__slider'),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    treshold: 0.1,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            var $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = function () {
    $('input, textarea').each(function (i, el) {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', function () {
          $(el).attr('placeholder', '');
        })
        .on('focusout', function () {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', function () {
    removeFieldPlaceholder();

    inputFilePlaceholder();
  });

  /**
   * Gravity form placeholder and label animation
   */
  function gfPlaceholder() {
    $('.gfield').each(function () {
      var str;
      var startStr = $(this).find('input, textarea').val();
      if (startStr) {
        $(this).find('.gfield_label').addClass('gfield_label--active').hide();
      }
      var gfield = $(this);

      $(this)
        .find('input, textarea')
        .on('input change', function () {
          var input = $(this);
          str = input.val();
          if (str) {
            gfield
              .find('.gfield_label')
              .addClass('gfield_label--active')
              .hide();
          } else {
            gfield
              .find('.gfield_label')
              .removeClass('gfield_label--active')
              .show();
          }
        });
    });
  }

  gfPlaceholder();

  $(document).bind('gform_post_render', function () {
    gfPlaceholder();

    $('.select2 select').select2({
      width: '100%',
      minimumResultsForSearch: Infinity,
    });
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 100 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  var scrollFixed;
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');

      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        scrollFixed = $(window).scrollTop();
        $('.body__inner').addClass('is-active');
      } else {
        $('.body__inner').removeClass('is-active');
        $(window).scrollTop(scrollFixed);
      }
    })
    .on('changed.zf.mediaquery', function () {
      $('.body__inner').removeClass('is-active');
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
var oTop = $('#numberCounter').length
  ? $('#numberCounter').offset().top - window.innerHeight
  : false;
var a = 0;

$(window).on('scroll', function () {
  /**
   * Number counter for home page
   */
  if (a == 0 && $(window).scrollTop() > oTop) {
    $('.js-number-spin').each(function () {
      var $this = $(this),
        countTo = $this.attr('data-count');
      $({
        countNum: $this.text(),
      }).animate(
        {
          countNum: countTo,
        },
        {
          duration: 2000,
          easing: 'swing',
          step: function () {
            $this.text(Math.floor(this.countNum));
          },
          complete: function () {
            $this.text(this.countNum);
          },
        }
      );
    });
    a = 1;
  }
});
